import { $themeBreakpoints } from '@themeConfig';

export default {
  namespaced: true,

  state: {
    baseURL: 'https://api.rhelite.tailormadesoftwaresolutions.com/storage/app/public/images/',
    profileURL: 'https://api.rhelite.tailormadesoftwaresolutions.com/storage/app/public/profile/',
    fileURL: 'https://api.rhelite.tailormadesoftwaresolutions.com/storage/app/public/files/',
    purchaseOrderURL: 'https://api.rhelite.tailormadesoftwaresolutions.com/storage/app/public/purchase/',
    saleOrderURL: 'https://api.rhelite.tailormadesoftwaresolutions.com/storage/app/public/sale/',
    settingURL: 'https://api.rhelite.tailormadesoftwaresolutions.com/storage/app/public/settings/',

    windowWidth: 0,
    shallShowOverlay: false,
    token: '',
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },

    isAuthenticated(state) {
      return state.token !== '';
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
  },
  actions: {},
};
